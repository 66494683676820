import React, { useState } from 'react';
import './App.css';

function App() {
  const [companyName, setCompanyName] = useState('');
  const [passwords, setPasswords] = useState([]);
  const [setCopySuccess] = useState('');
  const replacements = {
    "a": ["4","@"],
    "e": ["3"],
    "g": ["6"],
    "s": ["$", "5"],
    "i": ["1", "!"]
  };
  const commonSuffixes = ["2023", "2024", "123", "!", "1234", "!!", "2024!", "2023!"];

  function generateReplacements(str, replacements, maxReplacements = 6) {
    let result = [str];
    let replaceCount = 0;

    const applyReplacement = (char, rep, index) => {
      let newResults = [];
      result.forEach(existingStr => {
        newResults.push(existingStr.substring(0, index) + rep + existingStr.substring(index + 1));
      });
      return newResults;
    }

    for (let i = 0; i < str.length && replaceCount < maxReplacements; i++) {
      const char = str[i];
      if (replacements[char]) {
        replacements[char].forEach(rep => {
          result = applyReplacement(char, rep, i);
        });
        replaceCount++;
      }
    }
    return result;
  }



  function generateFirstCharCaseVariations(str) {
    if (str.length === 0) return [''];

    const firstChar = str[0];
    const restOfString = str.slice(1).toLowerCase();

    return [
      firstChar.toLowerCase() + restOfString,
      firstChar.toUpperCase() + restOfString
    ];
  }



  function generatePasswords() {
    const caseVariations = generateFirstCharCaseVariations(companyName);
    let generatedPasswords = new Set(); // Using Set to avoid duplicates

    // Add plain company name with suffixes
    commonSuffixes.forEach(suffix => {
      caseVariations.forEach(variant => {
        generatedPasswords.add(variant + suffix);
      });
    });

    // Generate passwords with up to two replacements for each case variation
    caseVariations.forEach(pwd => {
      // One replacement
      generateReplacements(pwd, replacements, 1).forEach(replacement => {
        commonSuffixes.forEach(suffix => {
          generatedPasswords.add(replacement + suffix);
        });
      });
      // Two replacements
      generateReplacements(pwd, replacements, 2).forEach(replacement => {
        commonSuffixes.forEach(suffix => {
          generatedPasswords.add(replacement + suffix);
        });
      });
    });

    setPasswords(Array.from(generatedPasswords));
  }






  const copyToClipboard = () => {
    const textToCopy = passwords.join('\n');
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Remove message after 2 seconds
    }, () => {
      setCopySuccess('Failed to copy!');
    });
  };

  return (
    <div className="App">
      <header className="App-header">
      <a href="https://adversis.io" target="_blank" rel="noopener noreferrer">
    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" />
</a>

        <div id="searchbox">
          {passwords.length > 0 && (
            <button className="left" onClick={copyToClipboard}>Copy All</button>
          )}
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Enter company name"
          />
          <button className="right" onClick={generatePasswords}>Generate</button>
        </div>
        <div>
          {passwords.map((pwd, index) => (
            <p key={index}>{pwd}</p>
          ))}
        </div>
      </header>
    </div>
  );
}

export default App;

